import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m1845 1797 c49 -105 113 -241 141 -302 29 -60 68 -145 88 -187 20 -43
52 -112 71 -153 19 -41 36 -80 38 -85 1 -6 -30 -21 -69 -35 -39 -13 -119 -46
-179 -74 -59 -28 -109 -51 -110 -51 -1 0 -17 34 -35 75 -18 41 -36 75 -40 75
-4 0 -32 -54 -61 -121 -61 -138 -80 -160 -224 -269 -90 -68 -318 -284 -406
-385 -67 -77 -165 -201 -213 -270 -86 -122 -85 -116 28 119 42 87 76 160 76
161 0 2 25 56 56 121 31 66 82 175 114 244 32 69 74 161 95 205 21 44 102 217
180 385 78 168 162 346 185 395 63 134 109 234 136 293 12 28 27 52 31 52 4 0
48 -87 98 -193z m1930 163 c39 -11 95 -33 125 -48 78 -38 181 -139 221 -217
19 -35 31 -66 29 -68 -15 -13 -274 -189 -284 -193 -7 -3 -27 14 -45 36 -42 53
-96 88 -162 106 -184 48 -369 -42 -369 -181 0 -77 43 -119 197 -192 341 -159
434 -212 548 -308 45 -38 140 -154 132 -162 -2 -2 -35 15 -73 39 -109 66 -339
173 -474 222 -196 69 -380 113 -575 136 -101 12 -103 14 -137 99 -17 45 -22
78 -22 171 -1 141 20 213 94 318 101 144 243 224 470 266 61 11 257 -3 325
-24z m-800 -934 c309 -15 676 -123 1015 -298 206 -107 215 -115 242 -201 29
-91 30 -241 4 -332 -62 -209 -218 -351 -459 -417 -110 -30 -352 -36 -465 -13
-211 44 -382 155 -484 314 -15 25 -28 50 -28 56 0 9 296 225 318 232 4 2 20
-16 35 -38 40 -56 121 -113 197 -140 52 -18 86 -23 175 -23 140 0 210 23 273
88 38 40 42 50 42 93 0 26 -4 53 -8 58 -19 27 -290 142 -433 185 -246 73 -407
95 -638 87 -156 -5 -363 -35 -384 -56 -4 -4 24 -72 62 -152 38 -79 91 -193
119 -254 28 -60 71 -155 97 -210 68 -143 115 -247 115 -251 0 -3 -102 -3 -227
-2 l-227 3 -57 125 c-127 283 -261 575 -265 579 -12 13 -185 -97 -299 -189
-163 -133 -343 -323 -439 -463 l-39 -57 -224 0 -225 0 23 38 c132 218 451 573
679 755 305 244 665 410 1016 467 78 12 301 28 349 23 17 -1 80 -5 140 -7z
m-2501 -1648 c13 -122 36 -364 36 -380 0 -14 -9 -18 -40 -18 -39 0 -40 1 -40
35 0 33 -2 35 -35 35 -33 0 -35 -2 -35 -35 0 -34 -1 -35 -40 -35 -48 0 -47
-18 -20 250 11 107 20 196 20 198 0 1 33 2 74 2 l75 0 5 -52z m383 18 c22 -26
23 -34 23 -181 0 -98 -4 -164 -12 -180 -18 -39 -57 -55 -138 -55 l-70 0 0 226
0 227 87 -5 c79 -4 88 -7 110 -32z m355 7 c15 -14 38 -71 38 -95 0 -5 -20 -8
-45 -8 -39 0 -45 3 -45 20 0 24 -29 39 -40 20 -13 -22 1 -50 46 -86 77 -64
104 -156 64 -222 -24 -39 -62 -55 -111 -47 -54 9 -81 44 -87 110 l-4 55 45 0
46 0 3 -42 c2 -31 8 -44 21 -46 14 -3 17 4 17 38 0 38 -6 48 -60 103 -61 62
-76 96 -65 146 7 32 39 66 70 74 31 8 88 -3 107 -20z m368 -1 c33 -25 38 -47
39 -182 1 -168 -12 -206 -76 -231 -41 -15 -88 -3 -117 32 -25 30 -26 34 -26
179 0 96 4 159 12 176 25 53 113 67 168 26z m370 -18 l0 -46 -42 4 -43 3 -3
-37 -3 -38 45 0 46 0 0 -39 c0 -40 0 -40 -42 -43 l-43 -3 -3 -102 -3 -103 -39
0 -39 0 -3 215 c-2 118 -1 220 0 225 2 6 41 10 88 10 l84 0 0 -46z m558 26
c38 -23 52 -54 52 -116 l0 -45 -42 3 -43 3 -3 48 c-3 38 -7 47 -21 47 -14 0
-20 -10 -25 -42 -7 -51 -2 -236 7 -244 3 -4 13 -4 22 -1 11 5 15 21 15 57 l0
50 45 0 45 0 0 -60 c0 -51 -4 -64 -27 -90 -53 -59 -157 -43 -184 27 -6 17 -9
89 -7 175 3 138 4 148 27 172 37 39 91 45 139 16z m392 -16 c23 -22 24 -32 28
-151 4 -135 -5 -195 -37 -234 -14 -18 -29 -23 -70 -23 -47 -1 -56 2 -82 32
l-30 34 3 162 c3 139 6 165 21 180 41 42 125 42 167 0z m274 19 c3 -10 13 -58
23 -108 l18 -90 3 108 3 107 39 0 40 0 0 -226 0 -225 -42 3 -42 3 -22 105 -22
105 -1 -107 -1 -108 -45 0 -45 0 0 225 0 225 44 0 c34 0 46 -4 50 -17z m380
-5 c3 -13 14 -61 24 -108 l20 -85 1 108 1 107 40 0 40 0 0 -226 0 -225 -42 3
-42 3 -21 105 -20 105 -3 -107 -3 -108 -44 0 -45 0 0 225 0 225 45 0 c38 0 45
-3 49 -22z m466 -17 c0 -40 0 -40 -42 -43 l-43 -3 -3 -47 -3 -48 45 0 46 0 0
-40 0 -40 -46 0 -45 0 3 -52 3 -52 45 0 45 -1 3 -42 3 -43 -90 0 -91 0 -1 198
c0 108 -3 209 -5 225 l-5 27 91 0 90 0 0 -39z m303 30 c49 -19 67 -50 67 -113
l0 -58 -39 0 -40 0 -3 48 c-3 39 -6 47 -23 47 -19 0 -20 -8 -23 -138 -2 -119
0 -138 14 -144 22 -8 34 14 34 65 l0 42 43 0 42 0 -5 -68 c-6 -79 -17 -97 -70
-113 -48 -15 -101 2 -127 39 -15 23 -18 48 -18 167 0 160 10 200 59 221 37 16
57 17 89 5z m407 -30 c0 -36 -3 -40 -27 -43 l-28 -3 -3 -182 -2 -183 -40 0
-40 0 -2 183 -3 182 -27 3 c-25 3 -28 7 -28 43 l0 39 100 0 100 0 0 -39z"/>
<path d="M387 1810 c-3 -25 -9 -78 -12 -117 -6 -72 -6 -73 18 -73 22 0 23 2
19 63 -2 34 -7 87 -11 117 l-8 55 -6 -45z"/>
<path d="M750 1705 c0 -136 1 -145 19 -145 27 0 31 18 31 138 0 126 -6 152
-31 152 -18 0 -19 -10 -19 -145z"/>
<path d="M1485 1838 c-3 -8 -4 -72 -3 -144 3 -121 4 -129 23 -129 19 0 20 7
20 140 0 125 -2 140 -18 143 -9 2 -19 -3 -22 -10z"/>
<path d="M2796 1835 c-9 -25 -7 -248 3 -264 5 -8 16 -11 25 -8 14 6 16 25 14
144 -3 118 -5 138 -19 141 -9 2 -19 -4 -23 -13z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
